<template> 
    <div class='image-wrapper image-wrapper-2 d-flex flex-column flex-lg-row center' > 
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3588.4946729200738!2d-80.22491424917399!3d25.91897750831746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9ae3716d0cabd%3A0x929eda3f640f52e0!2s1400%20NW%20159th%20St%2C%20Miami%2C%20FL%2033169!5e0!3m2!1sen!2sus!4v1645062530809!5m2!1sen!2sus" :width="width" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

      
      <div class='image-side image-side-2 d-flex align-center flex-column grey '>
        <div class='title-div'> <p class='title-text text-h2 mt-10 '> Contacts</p> </div>
        <br> 
        <div class="content-div mt-2">
          <span class='content-span text-subtitle-1 text-md-subtitle-1'> <b>Florida Furniture Distributors.</b><br>
                                1400 NW 159TH ST<br>
                                Miami, FL 33169 <br>
                                <b>Phone:</b>&nbsp;305-967-8066 <br>
                                <b>Email:</b>&nbsp;Orders@ffdFurniture.com
                                </span>
        </div>
        <div class='title-div'> <p class='title-text text-h2 mt-10 '> Hours </p> </div>
        <br> 
        <div class="content-div mt-2  mb-10">
          <span class='content-span text-subtitle-1 text-md-subtitle-1'> Warehouse pickup hours:<br>
                                Mon-Fri,&nbsp;10:00 AM to 4:00 PM<br>
                                Customer Service hours:<br>
                                Mon-Fri,&nbsp;9:00 AM to 5:00 PM 
                                </span>
        </div>
        <!-- <div class="button-div ">
            <v-btn 
              class="text-button card-button mt-16 text-xs-caption text-lg-button button"
              outlined
              color="black"
            >
              Explore Bedroom Collections
            </v-btn>
          </div>  -->
      </div>   
    </div>
</template>

<script>
    export default {
        name: 'Panel12',
        computed: {
    width () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '100%'
          case 'md': return '100.1%'
          case 'lg': return '100%'
          case 'xl': return '70%'
        }
      },
    } 
    }
</script>

<style scoped> 
    .image-wrapper{
    width: 80vw;
    height: 80%;
 }
  .span {
    width: 90vw;
    height: 12vh;
    border-bottom:1px solid;
    margin-bottom: 15px;
    
  }
  .span span {
    margin-bottom: 20px;
  }

  .image-wrapper-2 {
    margin:0;
    margin-top: 8vh;
  }

  .image-side-2 {
    max-width: 100%;
    min-width: 31%;
  }

  .title-div {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .content-div {
    width: 90%;
    height: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 1.45rem;
    font-weight: 300;
  }

  .button-div {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    margin-bottom: 6vh;
  }

  .content-span{
    width: 100%;
    overflow-x: hidden;
  }


</style>