<template> 
    <div class='wrapper'>
        <span class='text-wrapper'>
            <h1 class='text-h3 mb-10'> Oops! You found a page that doesn't exist</h1>
            <v-btn
            large
            outlined
            rounded
            href="/"
            
            > Go back home </v-btn>
        </span>
    </div>
</template>

<script>
    export default {
        name: "PageNotFound"
    }

</script>

<style scoped>
    .wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .text-wrapper {
        text-align: center;
        width: 30vw;
    }
</style>