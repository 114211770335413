<template> 
 
  <v-card 
  max-width="425"
  min-width="280"
  elevation='10'
  class='mb-5 v-card-details'
  >
  <router-link :to="'/products/' + slug" class='router-link'>
    <div class='card'> 
        <v-img
        height="250"
        :src="imageLink"
        contain
      ></v-img>
       <div class='bottom'>
         <div class="title">
        <v-card-title>{{productTitle}}</v-card-title>
      </div>
      <div class='body'> <v-card-text>
        <p class='text-h6 mt-7 ml-2'> <span class='text-body-1 price'>Price:</span> <span v-if='(price > 0)'>$</span>{{price}} </p>
      </v-card-text>
      </div>
      <div class="button-div d-flex justify-center">
      </div>
       </div>
    </div>
    </router-link>
  </v-card>
  

</template> 


<script>

export default {
    name: 'ProductScrollCardDetails',
    props: ['productTitle', 'price', 'imageLink', 'products', 'slug'],
    data () {
        return {
            ImageLink: this.imageLink
        }
    },

}
</script>

<style scoped>
  .v-card:first-of-type {
    margin-left: 20px;
    
  }

  .v-card-details {
    height: 375px;
    margin-left: 75px;
    margin-right: 2px;
    margin-top: vh;
  }
 
    /* All items are treated as being the first of type. Possible solution: Pass a custom class from horizontalscroll so that it only affects the first element that has that class. 
  .card-button:first-of-type {
   
  }

  */
  .title {
    height: 10%;
  }
  .body {
    height: 30%;
  }
  .button-div {
    height: 10%;
  }

  .router-link{
    text-decoration: none;
    color: black;

  }

  .bottom {
    background-color: #BFB6AE;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .price {
    color: white;
  }
  .card {

    height: 125px;

  }

</style>
