<template>
  <div class="main-wrapper-flex">
    <panel-1></panel-1>
    <span class='header-text text-h4'> OUR STORY IS ONE OF INNOVATION, AND A STRIVE TO EXCELLENCE. </span>
    <div class='second-section text-lg-body-1 d-column d-md-flex '> 
      <div class='first-half text-subtitles-1'>We serve as a direct factory importer and as a result, we are able to market our products at lower prices and offer quicker delivery to our customers than many of our competitors. The flexibility, as well as industry best turn around times, has allowed our customer base to grow to over 1,300 retailers throughout the United States. The mission of Florida Furniture Distributors is to establish positive relationships with its customers while providing quality products at competitive prices. We have been extremely successful at accomplishing our mission consecutively for the past 5 years. </div>
      <div class="second-half text-subtitles-1">
          We maintain a competitive product edge on our container program due to the fact that our overseas warehouses provide us with the flexibility to satisfy customer needs by providing containers of mixed products. In addition, our container program specialists work with customers to build products based on customer specific requirements. Our marketing and merchandising efforts are geared toward providing a broad selection of styles,  at competitive prices, quick delivery to the consumer and strong customer support.
      </div>

    </div>
    
    <panel-2 class='mt-16'></panel-2>
    <mailing-list></mailing-list>
    <site-footer></site-footer>
  </div>
</template>

<script> 
  import Panel1 from '../components/About/Panel1.vue'
  import Panel2 from '../components/About/Panel2.vue'
  import MailingList from '../components/MailingList.vue'
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'About',
    components: {
      Panel1,
      Panel2,
      MailingList,
      SiteFooter
    },
    created () {
      window.scrollTo(0,0)
    }
  }
</script>

<style>
 .main-wrapper-flex {
   width: 100vw;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }

  .text-subtitles-1 {
    color: rgb(0,0,0);
    font-family: Roboto, sans-serif;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 28px;
    font-size: 19px;
  }


 .header-text {
   width: 40%; 
   display: flex;
   text-align: center;
   justify-content: center;
   margin-top: 5vh;
   border-bottom: 1px solid;
   border-top: 1px solid;
   padding-top: 2vh;
   padding-bottom: 2vh;
 }
 
.second-section {
  width: 80%;
  min-height: 30vh;
  justify-content: space-around;
  align-items: center;
}

.first-half {
  min-width: 40%;
  max-width: 100%;
  min-height: 20vh;
  padding: 20px;

}

.second-half {
  min-width: 40%;
  max-width: 100%;
  min-height: 20vh;
  padding: 20px;
}

.spacer {
  height: 20vh;
}

</style>