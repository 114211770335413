<template> 
    <div class='image-wrapper image-wrapper-2 d-flex flex-column flex-lg-row justify-space-between' > 
      <v-img 
      src='https://images-global-icon.s3.us-east-2.amazonaws.com/South+Beach+2+Black+(59708)+(1).JPG' 
      :max-width='width'
      max-height='800px'
      fill
      href='/'>
      </v-img>
      
      <div class='image-side image-side-2 d-flex align-center flex-column grey '>
        <div class='title-div'> <p class='title-text text-h2 mt-10 '> About us</p> </div>
        <br> 
        <div class="content-div mt-2 ">
          <span class=' '> Florida Furniture Distributors's story has evolved from humble beginnings to a diverse and innovative landscape of products that have propelled us to being one of the largest wholesale furniture brands in the world. </span>
        </div>
        <!-- <div class="button-div ">
            <v-btn 
              class="text-button card-button mt-16 text-xs-caption text-lg-button button"
              outlined
              color="black"
            >
              Explore Bedroom Collections
            </v-btn>
          </div>  -->
      </div>   
    </div>
</template>

<script>
    export default {
        name: 'Panel1',
        computed: {
    width () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '100%'
          case 'md': return '100%'
          case 'lg': return '70%'
          case 'xl': return '70%'
        }
      },
    } 
    }
</script>

<style scoped> 
    .image-wrapper{
    width: 90vw;
    height: 100%;
 }
  .span {
    width: 90vw;
    height: 12vh;
    border-bottom:1px solid;
    margin-bottom: 15px;
    
  }
  .span span {
    margin-bottom: 20px;
  }

  .image-wrapper-2 {
    margin:0;
    margin-top: 8vh;
  }

  .image-side-2 {
    max-width: 100%;
    min-width: 25%;
  }

  .title-div {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .content-div {
    width: 90%;
    height: 50%;
    display: flex;
    text-align: center;
    font-size: 1.45rem;
    font-weight: 300;
  }

  .button-div {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    margin-bottom: 6vh;
  }
</style>